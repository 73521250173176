import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">How our</span>
            Coinbase Clone Script work?</h2>
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/coinbase-workflow.webp"
                  alt="Working flow of our Coinbase clone software"
                  width={1000}
                />
      </div>
    </section>
  )
}

export default HowOur